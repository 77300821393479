




import Vue from "vue";

export default class ErrorPage extends Vue{

}
